































































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import {CreateBranchPayload, LocationType} from "@/components/profile/branches/BranchDtos";
import Workspaces from "@/state/Workspaces";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import {prettyEnum} from "@/utils/StringUtils";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {namespace} from "vuex-class";
import CompanyService from "@/services/CompanyService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {ifValid} from "@/utils/ComponentUtils";
import PortalDate from "@/components/common/PortalDate.vue";
import AddressDTO from "@/dto/AddressDTO";

const AppModule = namespace("App");

@Component({
	computed: {
		LocationType() {
			return LocationType
		}
	},
	components: {PortalDate, PortalCheckbox, PortalAddress, PortalSelect, PortalTextarea, PortalInput}
})
export default class BranchCreationModal extends Vue {
	private message = "";

	private successful = false;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Prop()
	private onSuccess!: () => void;

	private req: CreateBranchPayload = new CreateBranchPayload();

	private workspace = Workspaces.getCurrent;

	public created(): void {
		this.req.companyId = this.workspace.id;
	}

	private buildOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title(prettyEnum(LocationType.DOMESTIC)).value(LocationType.DOMESTIC).build(),
			SelectOption.builder().title(prettyEnum(LocationType.FOREIGN)).value(LocationType.FOREIGN).build()
		]
	}

	private save() {
		ifValid(this, () => {
			this.startLoading();
			if (this.req.legalAddress && this.req.legalAddress.fullAddress) {
				this.req.legalAddress = null;
			}
			if (this.req.mailingAddress && this.req.mailingAddress.fullAddress) {
				this.req.mailingAddress = null;
			}
			if (this.req.actualAddress && this.req.actualAddress.fullAddress) {
				this.req.actualAddress = null;
			}
			if (this.req.registrationAgentAddress && this.req.registrationAgentAddress.fullAddress) {
				this.req.registrationAgentAddress = null;
			}
			CompanyService.createBranch(this.req).then(
				() => {
					this.stopLoading();
					this.onSuccess();
					this.$modal.hideAll()
				},
				err => this.processError(err)
			)}
		)
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}
}

