




















import {Component, Vue} from "vue-property-decorator";
import BranchDto from "@/components/profile/branches/BranchDtos";
import {namespace} from "vuex-class";
import CompanyService from "@/services/CompanyService";
import Workspaces from "@/state/Workspaces";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import BranchCreationModal from "@/components/profile/branches/BranchCreationModal.vue";
import BranchView from "@/components/profile/branches/BranchView.vue";

const AppModule = namespace("App");

@Component({
	components: {BranchView}
})
export default class BranchList extends Vue {

	private message = "";

	private successful = false;

	private headQuarter: BranchDto | null = null;

	private branches: Array<BranchDto> = [];

	private workspace = Workspaces.getCurrent;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	public mounted(): void {
		this.loadBranches()
	}

	private loadBranches() {
		this.startLoading();
		CompanyService.getBranches(this.workspace.id).then(
			res => {
				this.branches = res.data
				this.stopLoading();
			},
			err => this.processError(err)
		)
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	private openCreationModal(): void {
		this.$modal.show(
			BranchCreationModal,
			{
				onSuccess: () => this.loadBranches()
			}
		)
	}
}

