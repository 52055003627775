





































































import {Component, Prop, Vue} from "vue-property-decorator";
import BranchDto from "@/components/profile/branches/BranchDtos";
import PortalInput from "@/components/common/PortalInput.vue";
import RouteNames from "@/router/RouteNames";
import {WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import {prettyEnum} from "@/utils/StringUtils";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";

@Component({
	computed: {
		WorkspaceTypePathElement() {
			return WorkspaceTypePathElement
		}
	},
	methods: {
		prettyEnum,
		Workspaces() {
			return Workspaces
		},
		RouteNames() {
			return RouteNames
		}
	},
	components: {BTPortalCollapse, PortalInput}
})
export default class BranchView extends Vue {
	@Prop({type: Object})
	private branch!: BranchDto;

	private isOpenMirror = false;

	@Prop({default: false})
	private isOpen!: boolean

	public created(): void {
		this.isOpenMirror = this.isOpen;
	}
}

