var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-t-portal-collapse',{attrs:{"id":_vm.branch.id,"label":_vm.branch.title}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name: _vm.RouteNames().PROFILE_BRANCH, params: {branchId: _vm.branch.id, wsType: _vm.prettyEnum(_vm.Workspaces().getCurrent.type).substring(0, 1).toLowerCase(), wsId: _vm.Workspaces().getCurrent.id}}}},[(_vm.branch.description)?_c('div',{staticClass:"p-3 m-2",style:({
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px'
				})},[_c('p',{staticClass:"mb-1",staticStyle:{"font-size":"1.3rem","margin-right":"10px"}},[_vm._v("Description:")]),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.branch.description))])]):_vm._e(),(_vm.branch.registrationDate)?_c('div',{staticClass:"d-flex align-items-center justify-content-between p-3 m-2",style:({
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px'
				})},[_c('p',{staticClass:"mb-1",staticStyle:{"font-size":"1.3rem","margin-right":"10px"}},[_vm._v("Registration date:")]),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s((_vm.branch.registrationDate)))])]):_vm._e(),(_vm.branch.legalAddress && _vm.branch.legalAddress.fullAddress)?_c('div',{staticClass:"d-flex align-items-center justify-content-between p-3 m-2",style:({
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px'
				})},[_c('p',{staticClass:"m-0",staticStyle:{"font-size":"1.3rem","margin-right":"10px"}},[_vm._v("Legal address:")]),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.branch.legalAddress ? _vm.branch.legalAddress.fullAddress : "None"))])]):_vm._e(),(_vm.branch.mailingAddress && _vm.branch.mailingAddress.fullAddress)?_c('div',{staticClass:"d-flex align-items-center justify-content-between p-3 m-2",style:({
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px'
				})},[_c('p',{staticClass:"m-0",staticStyle:{"font-size":"1.3rem","margin-right":"10px"}},[_vm._v("Mailing address:")]),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.branch.mailingAddress ? _vm.branch.mailingAddress.fullAddress : "None"))])]):_vm._e(),(_vm.branch.actualAddress && _vm.branch.actualAddress.fullAddress)?_c('div',{staticClass:"d-flex align-items-center justify-content-between p-3 m-2",style:({
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px'
				})},[_c('p',{staticClass:"m-0",staticStyle:{"font-size":"1.3rem","margin-right":"10px"}},[_vm._v("Actual address:")]),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.branch.actualAddress ? _vm.branch.actualAddress.fullAddress : "None"))])]):_vm._e(),(_vm.branch.registrationAgentAddress && _vm.branch.registrationAgentAddress.fullAddress)?_c('div',{staticClass:"d-flex align-items-center justify-content-between p-3 m-2",style:({
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px'
				})},[_c('p',{staticClass:"m-0",staticStyle:{"font-size":"1.3rem","margin-right":"10px"}},[_vm._v("Registration agent address:")]),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.branch.registrationAgentAddress ? _vm.branch.registrationAgentAddress.fullAddress : "None"))])]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }